.nav-item {
	.rc-menu-vertical {
		border: none;
		margin: 5px 0 0;
		padding: 0px;
		color: $menu-dark-text-color;
		box-shadow: none;

		&:hover {
			background: none;
		}

		.rc-menu-submenu-arrow {
			height: 30px;
			top: 12px;

			&:before {
				content: "\e315";
				font-family: Material Icons;
				font-size: 17px;
				position: absolute;
				right: -3px;
				top: -2px;
			}
		}

		&>.rc-menu-submenu-active {
			color: $menu-active-text-color;

			&>.rc-menu-submenu-title {
				background-color: transparent;
			}
		}

		&>.rc-menu-submenu>.rc-menu-submenu-title {
			padding: 7px 26px;

			&>.pcoded-micon {
				font-size: 1.2rem;
				padding: 4px 7px;
				margin-right: 7px;
				border-radius: 4px;
				width: 30px;
				display: inline-flex;
				align-items: center;
				height: 25px;
				text-align: center;
			}
		}
	}
}

.rc-menu-submenu {
	z-index: 999;

	.rc-menu {
		background-color: $menu-dark-background;
		color: $menu-dark-text-color;
		border-radius: 5px;

		.nav-link {
			color: black !important;
			font-size: 13px;

			&:active {
				color: $menu-active-text-color !important;
			}

			.pcoded-micon {
				padding-right: 5px;
			}
		}
	}
}

.rc-menu-item-active {
	background-color: transparent;
}

.rc-menu-submenu-selected,
.rc-menu-item-selected {
	background-color: transparent;
	color: $menu-active-text-color;
}

.rc-menu-vertical>.rc-menu-item {
	cursor: pointer;
}