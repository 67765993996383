/**  =====================
      Authentication css start
==========================  **/
.auth-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    min-width: 100%;
    min-height: 100vh;
    background-color: #FCFAFA;

    a,
    p>a {
        color: $theme-heading-color;
        font-weight: 600;
    }

    .btn-auth-gen {
        .btn-icon {
            width: 140px;
            height: 140px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 45px;

            small {
                font-size: 15px;
            }
        }
    }

    .input-group {
        background: transparent;
    }

    .card {
        margin-bottom: 0;
        box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.2);
    }

    >div {
        z-index: 5;
    }

    .loginContent {
        border: 1px solid #ddd;
        box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.2);
        border-radius: 5px;
        min-height: 315px;
        background-color: white !important;
    }

    .loginLogo {
        padding-right: 0;
        padding-left: 170px;
    }

    .auth-content {
        position: relative;
        width: 400px;
        padding: 15px;
        z-index: 5;

        &.multyform,
        &.subscribe {
            //width: 35%;
            display: inline-block;
        }

        .initReset {
            cursor: pointer;
            font-weight: 600;
            color: black;
        }
    }

    .resetPwdForm,
    .changePwdForm {
        position: relative;
        // width: 40%;
        padding: 0;
        z-index: 5;
        display: inline-block;
    }

    .changePwdForm {
        width: 45%;
    }

    @media only screen and (max-width: 768px) {
        max-width: 360px;

    }

    @media only screen and (max-width: 575px) {
        .card {
            .card-body {
                padding: 30px 15px;
            }
        }
    }

    .auth-icon {
        font-size: 30px;

        &:before {
            background: $theme-color;
            background-clip: inherit;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    &.offline {
        background-image: none;

        &:before {
            display: none;
        }
    }

    .auth-bg {
        .r {
            position: absolute;
            width: 300px;
            height: 300px;
            border-radius: 50%;


            &:first-child {
                top: -100px;
                right: -100px;
                background: $theme-color;
            }

            &:last-child {
                left: -100px;
                bottom: -100px;
                background: $theme-color2;

            }

            &.s {
                width: 20px;
                height: 20px;

                &:nth-child(2) {
                    top: 150px;
                    right: -150px;
                    background: $primary-color;
                }

                &:nth-child(3) {
                    left: -150px;
                    bottom: 150px;
                    background: $success-color;
                }
            }

            &:nth-child(odd) {
                animation: floating 7s infinite;
            }

            &:nth-child(even) {
                animation: floating 9s infinite;
            }
        }
    }

    .logoCont {
        text-align: center;
        position: relative;
    }

    .loginCont {
        text-align: center;
    }
}

/* image variant start */
.aut-bg-img {
    background-size: cover;
    /*background-image: url('../../../images/bg-images/bg4.jpg');*/
    // amit images
    background-repeat: no-repeat;
    background-position: center;
}

.aut-bg-img-side {
    p {
        line-height: 2;
    }
}

.aut-bg-img,
.aut-bg-img-side {

    .custom-select,
    .form-control {
        background: transparent;
    }
}

.auth-tabs {
    .card {
        // border-right: 10px;
    }

    .nav-pills {
        // margin-top: -80px;
    }

    .tab-content {
        overflow: hidden;
        position: relative;

        .tab-pane>* {
            position: relative;
            z-index: 5;
        }

        .auth-icon {
            position: absolute;
            bottom: 0;
            right: 0;
            font-size: 180px;
            transform: rotate(-30deg);
            z-index: 1;
            opacity: 0.2;
        }
    }
}

.LoginPage {
    .alignCenter {
        text-align: center;
    }

    .pointer {
        cursor: pointer;
        color: $custom-btn;
    }

    .pad-zero {
        padding: 0;
    }

    .loginInput {
        padding: 7px 10px 7px 28px;
        font-size: 13px;
        border: 1px solid #ccc;
        width: 250px;
        border-radius: 7px;

    }

    .inputImg {
        position: absolute;
        top: 10px;
        left: 10px;
        font-size: 13px;
    }

}

/* image varient start */
@keyframes floating {
    from {
        transform: rotate(0deg) translate(-10px) rotate(0deg);
    }

    to {
        transform: rotate(360deg) translate(-10px) rotate(-360deg);
    }
}


/**====== Authentication css end ======**/