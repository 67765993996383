/**  =====================
      content body styles
==========================  **/
html,
body {
  max-width: 100%;
  overflow-x: hidden;
  font-size: 12.5px;

  .fa {
    font-size: 14px;
  }

  .helpFile {
    #overlay {
      width: 98%;
      z-index: 1;
      height: 480px;
    }
    iframe {
      width: 100%;
      height: 480px;
    }
    #container {
      position: relative;
    }
    #overlay,
    iframe {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .filesError {
    top: 210px !important;
    .modal-content {
      border-color: #ccc;
      .modal-header {
        border-bottom-color: #ccc;
      }
      .modal-body {
        min-height: 100px;
        max-height: 200px;
        overflow-y: auto;
      }
    }
  }

  .modal-dialog {
    top: 80px;

    .sdIcons {
      .moreActions {
        padding-top: 0 !important;
      }
    }
  }

  .modalTop {
    top: 20px;
  }

  .react-datepicker {
    font-size: 0.99rem;
    font-family: inherit;
    border: 1px solid #ccc;

    .react-datepicker__header {
      border-bottom: 1px solid #ccc;

      .react-datepicker__day-names {
        font-weight: bold;
      }

      .react-datepicker__current-month {
        margin-bottom: 1px;
        font-size: 13px;
      }
    }

    .react-datepicker__month {
      .react-datepicker__day {
        width: 2.1rem;
        line-height: 2rem;
      }
    }
  }

  div.userPopOver {
    border-radius: 3px;
    width: auto;
    top: 0 !important;
    left: 3px !important;
    padding: 5px;

    div.popOverIcons {
      a,
      div {
        padding: 10px 15px 0;
      }
    }

    div.helpPopOver {
      font-size: 13.5px;
      display: block;
      padding: 0 5px 5px 5px;
      a,
      div {
        padding-top: 10px;
        cursor: pointer;
        svg {
          padding-top: 0;
          margin-top: -2px;
        }
        span {
          padding-left: 7px;
        }
      }
    }
  }

  div .actionPopOver.popover.bs-popover-bottom {
    top: -3px;
    left: -42px;
    border: 1px solid #ddd;
    padding: 5px 15px 5px 5px;
    background-color: #f3f6f9;

    .arrow {
      right: 38px;

      &:before {
        color: #f3f6f9;
      }
    }
  }

  .exportFile {
    font-size: 2.5vh;
    margin: 1px;
  }

  div.popoverMain {
    z-index: 999;
  }

  div.moreActionsDiv {
    top: 5px;
    left: -104px;

    .arrow {
      display: none;
    }

    .sortDiv {
      max-height: 240px;
      overflow-y: auto;
    }

    .sortList {
      .sortValue {
        position: relative;

        .sortText {
          padding: 3px 25px 5px 8px;
          cursor: pointer;
        }

        i {
          position: absolute;
          right: 0;
          top: 8px;
        }
      }
    }
  }

  div.filterCont {
    left: -160px;
  }

  div.groupOptions {
    div {
      padding: 5px 5px 0;
    }
  }

  .downloadFileImage {
    text-align: center;
    // margin-top: 90px;
    cursor: pointer;
  }

  .errData {
    padding: 5px 10px;
    border: 2px dotted #ddd;

    // height: 290px;
    // overflow-y: auto;
    .errorList {
      max-height: 250px;
      overflow-y: auto;
      border-bottom: 1px solid #ddd;
      margin-left: -10px;
    }

    .genHybrid {
      // border-bottom: 1px solid rgb(238, 238, 238);
      // box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.2);
      // padding: 10px 20px;
      margin: 10px 60px;
      font-weight: 600;
    }
  }

  .fileInfo {
    color: #353283;
    margin-right: 5px;
    font-size: 16px;
    margin-top: -5px;
    // cursor: pointer;
  }

  .labelInfo {
    position: absolute;
    margin-left: 5px;
    margin-top: 0;
  }

  .documentInfo {
    color: #e74c3c;
    margin-top: 4px;
    margin-left: -10px;
    font-size: 16px;
  }

  .btn-reject {
    background-color: #e74c3c !important;
    border-color: #e74c3c !important;
  }

  .btn-approve {
    background-color: #2ecc71 !important;
    border-color: #2ecc71 !important;
  }

  .bold {
    font-weight: bold;
  }

  .marksSearch {
    width: 100%;

    .square {
      height: 11px;
      width: 16px;
      background-color: #dafcde;
      display: inline-block;
      border: 2px solid greenyellow;
      margin-right: 3px;
    }

    label {
      width: 45%;
    }
  }

  .swal2-popup {
    font-size: 0.7rem !important;
    top: -10px;
    width: 30%;

    #swal2-content {
      font-size: 14px;
      font-weight: normal;
    }

    .swal2-styled.swal2-confirm {
      background-color: $custom-btn;

      &:active:hover {
        background-color: $custom-btn;
      }
    }
  }

  .swal2-show {
    // animation: swal2-show 1s !important;
    transition: opacity 0.15s linear !important;
    z-index: 99999;
  }

  .swal2-shown {
    z-index: 99999;
  }

  .swal2-icon {
    font-size: 7px !important;
  }

  .swal2-success {
    .swal2-success-ring {
      border-color: #2ecc71 !important;
    }

    .swal2-success-line-tip,
    .swal2-success-line-long {
      background-color: #2ecc71 !important;
    }
  }

  .swal2-error {
    border-color: #e74c3c !important;

    .swal2-x-mark-line-left,
    .swal2-x-mark-line-right {
      background-color: #e74c3c !important;
    }
  }

  .swal2-warning {
    border-color: #ff9800 !important;
    color: #ff9800 !important;
  }

  .highlightRed {
    color: #e74c3c;
    font-weight: bold;
  }

  .errInfo {
    padding-left: 5px;
    font-size: 19px;
    margin-bottom: 3px;
    color: #353283;
  }

  .navPrev {
    margin-top: 7px;
    margin-right: 5px;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 12px;
    position: absolute;
    left: -30px;
    top: -3px;
    padding: 4px 4px 3px 3px;
  }

  .navNext {
    margin-top: 7px;
    margin-left: 5px;
    cursor: pointer;
    position: absolute;
    right: -26px;
    padding: 4px 3px 3px 4px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 12px;
    top: -3px;
  }

  .disIcon {
    opacity: 0.5;
    cursor: default;
  }

  .selectDate {
    position: relative;

    .dateIcon,
    .calIcon {
      position: absolute;
      right: 15px;
      top: 7px;
      color: $custom-btn;
      font-size: 16px;
      opacity: 0.7;
    }

    .calIcon {
      right: 22px;
    }
  }

  .tableLoader {
    width: 3rem;
    height: 3rem;
    color: gray;
  }

  .btn i {
    margin-right: 5px;
  }

  .btn-primary {
    background-color: $custom-btn;
    border-color: $custom-btn-border;

    &:hover:active {
      background-color: #353283 !important;
      border-color: #353283 !important;
    }

    &:disabled {
      background-color: $custom-btn;
      border-color: $custom-btn-border;
    }
  }

  .btn-outline-primary {
    color: $custom-btn;

    &:hover:active {
      background-color: #353283 !important;
      border-color: #353283 !important;
    }
  }

  .notification-container-top-center {
    width: auto;

    .notification-success {
      background-color: #0aaf19;
      border-left: none !important;
      border-color: #0aaf19;
    }

    .notification-item.notification-danger {
      border-left: none !important;
      border-color: #e60e0e;
      background-color: #e60e0e !important;
    }

    .notification-warning {
      border-left: none !important;
      border-color: #fbb13d;
      background-color: #fbb13d;
    }

    .notification-message {
      max-width: 100%;
    }
  }
}

.defaultPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 80vh;
  font-size: x-large;
}

.modal-Lg {
  width: 80%;
  max-width: none !important;
}

.modal-85 {
  width: 85%;
}

.modal-md {
  width: 50%;
  max-width: none !important;
}

.modal-40 {
  width: 40%;
  max-width: none !important;
}

.breadcrumb-item a.bcBaseClass {
  &:active:hover:focus {
    color: $custom-btn !important;
  }

  &:not([link]):not([visited]) {
    color: $custom-btn !important;
  }
}

div.popOverIcons {
  display: block;
  font-size: 12.5px;

  .actions {
    padding: 5px 0;
    cursor: pointer;

    .actionsIcon {
      font-size: 17px;
      margin-top: -2px;
    }
  }

  i,
  span {
    color: black;
    cursor: pointer;
  }
}

div.modal-content {
  font-size: 12.5px;
}

div.main-body,
div.modal-content,
.popover,
.filesToolTip {
  /*****Common styles*****/
  .mainContainer {
    min-height: 600px;
  }

  .downloadAttachment {
    margin-top: 7px;
    margin-left: -5px;
    cursor: pointer;
    color: black;
    font-size: 13.5px;
  }

  .pipingMTCDetail {
    position: absolute;
    cursor: pointer;
    color: black;
    font-size: 13.5px;
    top: 6px;
  }

  .formNumber {
    width: 34%;
    margin-right: 10px;
    display: inline-block;
    height: calc(1.5em + 0.75rem + 2px);
    text-align: center;
  }

  .was-validated {
    .form-control {
      &:valid {
        border-color: #ced4da;
        background-image: none;
      }

      &:invalid {
        border-color: red;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.1rem);
        background-position: calc(100% - 10px) calc(0.1em + 0.4875rem);
      }
    }

    .invalidDateTimeField input {
      border: 1px solid red !important;
      border-radius: 5px;
    }

    .invalidSelect {
      div.css-bg1rzq-control,
      .css-yk16xz-control {
        border: 1px solid red;
        border-radius: 5px;
      }
    }
  }

  input.form-control {
    height: calc(1.3em + 0.75rem + 2px) !important;
    font-size: 13px;
  }

  input.gratingClip {
    height: calc(0.2em + 0.75rem + 2px) !important;
  }

  select.form-control {
    height: calc(1em + 1rem + 2px) !important;
    padding: 0 20px;
    font-size: 13px;
  }

  .form-check-input {
    margin-top: 0.2rem;
    margin-left: -1.5rem;
  }

  .form-check-label {
    color: inherit !important;
  }

  .modal-Lg {
    width: 80%;
    max-width: none !important;
  }

  .modal-header {
    padding: 0.5rem 1rem;
    background-color: #f5f5f5;

    .modal-title {
      font-size: 14px;
      font-weight: 600;
    }
  }

  .modal-footer {
    padding: 0.3rem 0.5rem;
  }

  div.btnCountGroup {
    display: inline;

    .countBtn {
      padding: 5px 15px;
    }
  }

  .filterApplied {
    background-color: #ddd;
  }

  .btn-soft {
    background-color: #f9f9f9;
    color: $custom-btn;
    border-color: #e3e3e3;
  }

  .borderRight {
    border-right: 1px solid #ddd;
  }

  .softBtns {
    display: inline;
    // border-right: 1px solid #ccc;
    margin-right: 3px;
    padding: 11px 22px 6px 5px !important;
    color: gray;
    vertical-align: super;
    box-shadow: -2px 1px 6px -2px rgba(0, 0, 0, 0.3);
    position: relative;

    .count {
      // width: 15px;
      // line-height: 10px;
      // border-radius: 50%;
      // text-align: center;
      font-size: 11px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color: #353283;
      color: white;
      min-width: 15px;
      border-radius: 50%;
      vertical-align: middle;
      top: 0;
      height: 15px;
      border: 2px solid $custom-btn;
      background-color: $custom-btn;
      color: white;
      position: absolute;
    }
  }

  .alignCenter {
    text-align: center;
  }

  .alignRight {
    text-align: right;
  }

  .iconText {
    padding-top: 5px;
    text-align: center;
    // margin-left: -35px;
    font-size: 13px;
  }

  .groupCard {
    margin-bottom: 10px;

    .groupHeader {
      border-bottom: 1px solid #eee;
      // background: linear-gradient(-180deg, #353283 0%, #fff 115%);
    }

    .groupBody {
      min-height: 180px;

      .content {
        padding-top: 5px;
        padding-bottom: 10px;
      }

      .groupIcon {
        text-align: center;
        // padding-left: 30px;
      }
    }
  }

  .padding-tp-10 {
    padding-top: 10px;
  }

  .padding-bt-10 {
    padding-bottom: 10px;
  }

  .padding-lt-10 {
    padding-left: 10px;
  }

  .padding-rt-10 {
    padding-right: 10px;
  }

  .padding-zero {
    padding: 0;
  }

  .padding-lt-zero {
    padding-left: 0;
  }

  .padding-rt-zero {
    padding-right: 0;
  }

  .padding-tp-5 {
    padding-top: 5px;
  }

  .padding-bt-5 {
    padding-bottom: 5px;
  }

  .padding-rt-5 {
    padding-right: 5px;
  }

  .padding-lt-5 {
    padding-left: 5px;
  }

  .margin-bt-10 {
    margin-bottom: 10px;
  }

  .margin-lt-5 {
    margin-left: 5px;
  }

  .floatRight {
    float: right;
  }

  .disInline {
    display: inline;
    padding-left: 20px;
  }

  .disabled {
    color: darkgray !important;
    cursor: initial !important;
  }

  .react-bootstrap-table-pagination-total {
    display: inline-block;
  }

  .table-bordered {
    th {
      border: 1px solid #ddd;
      border-bottom: 1px solid #e3e3e3;
    }
  }

  .totalRecords {
    padding: 4px;
    border: 1px solid #bbb;
    border-radius: 5px;
    font-weight: 600;
  }

  .tablePagination {
    width: 100%;

    ul {
      float: right;
    }
  }

  .wrapData {
    table-layout: fixed;
    width: 100%;

    td {
      white-space: break-spaces;
    }
  }

  table {
    tr {
      color: $theme-font-color;
      cursor: pointer;

      &:hover {
        background-color: rgba(4, 169, 245, 0.05);
      }

      th,
      td {
        padding: 0.4rem 0.75rem;
      }

      th {
        // white-space: normal !important;
        text-overflow: initial;
        background-color: #f0f1f3;
        font-weight: 600;

        .order-4 {
          &:before,
          &:after {
            opacity: 0.7;
            color: gray;
          }
        }

        .caret-4-asc {
          &:before {
            color: black;
          }

          &:after {
            opacity: 0.7;
            color: gray;
          }
        }

        .caret-4-desc {
          &:before {
            opacity: 0.7;
            color: gray;
          }

          &:after {
            color: black;
          }
        }
      }

      td {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .tableCheckBox {
      overflow: inherit;
    }

    .form-check-input {
      margin-top: 0;
      margin-left: 0;
    }

    .form-check-inline {
      padding-left: 1.25rem;
    }

    .btn {
      padding: 0;
      margin-bottom: 0;
      background: transparent;
      border: 0;
    }
  }

  ul.pagination {
    cursor: pointer;

    li.active {
      a {
        background-color: $custom-btn;
        border-color: $custom-btn-border;
      }
    }
  }

  .react-bootstrap-table-pagination-total {
    padding-top: 7px;
  }

  .react-bootstrap-table-pagination-list-hidden {
    display: none;
  }

  .page-link {
    padding: 0.3rem 0.6rem;
  }

  .form-control {
    background: transparent;

    &:disabled {
      background: #e9ecef;
    }
  }

  .invalid-feedback {
    color: red;
  }

  .form-group {
    margin-bottom: 0.5rem;
  }

  .formFieldset {
    .legendClass {
      font-size: 13px;
      font-weight: bold;
      overflow: hidden;
      position: relative;

      &:after {
        content: "";
        display: inline-block;
        height: 100%;
        margin-right: -100%;
        border-bottom: 1px solid #eee;
        bottom: 10px;
        position: absolute;
        margin-left: 5px;
        width: 67%;
      }
    }

    div.form-group {
      // margin-left: 10px;
    }
  }

  .btn {
    padding: 0.2rem 0.6rem;
    font-size: 13px;
  }

  div.actionIcons {
    i,
    svg {
      padding-right: 7px;
      cursor: pointer;
      color: $theme-font-color;
    }

    svg {
      font-size: 16px;
      margin-right: 4px;
      margin-top: -2px;
      padding-right: 2px;
    }
  }

  div.actionGroup {
    float: right;
    right: 0;
    position: relative;
  }

  .whiteText {
    color: $yiq-light-color;
  }

  .maxContent {
    width: max-content;
  }

  .searchFilter {
    margin: 3px 0;

    .sort {
      button {
        white-space: break-spaces;
        border: none !important;
      }

      .dropdown-menu {
        padding: 15px !important;
      }
    }

    .filter {
      .dropdown-menu {
        left: -5vh !important;

        &:before {
          left: 95px;
        }
      }
    }

    .actions {
      button {
        padding: 3px 6px;

        &:after {
          border: none !important;
        }
      }

      .dropdown-menu {
        left: -5vh !important;
        padding: 10px 3px !important;

        &:before {
          left: 73px;
        }
      }
    }

    .disIcons {
      display: inline-block !important;

      button {
        margin-right: 5px !important;
        margin-bottom: 0 !important;
        padding: 4px 4px 5px !important;
      }
    }

    .dropdown-toggle {
      background: none;
      border: 1px solid #d5d5d5;
      color: inherit;
      padding: 3px 4px;

      &:after {
        margin-left: 0;
        vertical-align: 0.05em;
      }
    }

    @supports (-moz-appearance: none) {
      .dropdown-toggle {
        &:after {
          position: absolute;
          top: 12px;
        }
      }
    }

    .dropdown-menu {
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);

      .filterHeader {
        text-align: center;
        font-size: 14px;
        font-weight: bold;
      }

      .filterCondition {
        border: 1px solid #777;
        background: #eee;
      }
    }

    .sortDiv {
      max-height: 240px;
      overflow-y: auto;
    }

    .sortList {
      .sortValue {
        position: relative;

        .sortText {
          padding: 3px 25px 5px 8px;
          cursor: pointer;
        }

        i {
          position: absolute;
          right: 0;
          top: 8px;
        }
      }
    }
  }

  @media (min-width: 1200px) {
    .filterContainer {
      max-width: 1140px;
      min-width: 180px;
    }
  }

  .filterContainer {
    select,
    input {
      border: 1px solid #ccc;
      border-radius: 3px;
    }
  }

  .searchDiv {
    padding-right: 0;
    padding-top: 2px;
    margin-bottom: 5px;
    padding-left: 7px;

    input {
      border-color: #ddd;
    }

    .searchIcon,
    .searchclear {
      // z-index: 9999;
      top: 9px;
      position: absolute;
      font-size: larger;
      cursor: pointer;
    }

    .searchIcon {
      right: 5px;
    }

    .searchclear {
      right: 24px;
    }

    .searchBox {
      padding-left: 5px;
      height: calc(1.2em + 0.75rem + 2px) !important;
      border-radius: 3px;
      font-weight: bold;
      border: 1px solid #e3e3e3;

      &:focus {
        // border: 1px solid #ced4da;
        box-shadow: none;
      }
    }
  }

  div.generalBtns {
    // padding-bottom: 3px;
    border-bottom: 1px solid #eee;
    margin: 0 -25px 10px;
    padding-left: 10px;
    box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.05);
  }

  .encloseCircle {
    display: inline-block;
    border-radius: 60px;
    border: 1px solid $custom-btn;
    box-shadow: 0 0 2px #888;
    background-color: $custom-btn;
    color: white;
    padding: 0.5em 0.6em;
    cursor: pointer;
    font-size: 16px;
  }

  .viewBtns {
    float: right;
    margin-right: 5px;

    div.gridView,
    div.listView {
      display: inline-block;
      padding-right: 3px;

      .btn {
        width: auto;
        height: auto;
        padding: 0.25rem 0.5rem;
      }
    }
  }

  .card-body {
    padding: 10px 25px;
    font-size: 12.5px;
  }

  .card-header h5 {
    &:after {
      background-color: $custom-btn;
    }
  }

  .react-bootstrap-table-pagination {
    padding-top: 10px;
  }

  div.actionPopOver {
    padding: 10px;
    border-radius: 3px;
    width: auto;
  }

  .rdt .rdtPicker {
    width: 300px;
    left: -11px;
  }

  .disableElement {
    pointer-events: none;
    color: darkgray !important;
  }

  div.moreActions {
    padding: 2px 5px;
    border-radius: 3px;
    margin: 0 2px;
    border: 1px solid #ccc;
    cursor: pointer;
    display: inline-block;
    float: right;
  }

  .iconInprogress {
    color: $custom-btn;
    font-size: 12px;
  }

  .iconSuccess {
    color: green;
    font-size: 12px;
  }

  .iconFailed {
    color: #e94a4a;
    font-size: 12px;
  }

  div.createFormWizard {
    padding-top: 10px;

    div.modalWizardSteps {
      padding: 0 20px;

      div.wizardStep {
        width: 119px !important;
      }
    }

    div.formWizardSteps,
    div.modalWizardSteps {
      clear: both;
      display: flex;

      div.wizardStep {
        position: relative;
        text-align: center;
        // width: 75px;
        width: auto;
        padding: 0 10px;
        line-height: 30px;
        margin-bottom: 5px;
        z-index: 1;

        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 3%;
          height: 50%;
          width: 100%;
          transform: skew(25deg);
          border: 1px solid #ddd;
          border-bottom: none;
        }

        &:after {
          content: "";
          position: absolute;
          top: 50%;
          left: 3%;
          height: 50%;
          width: 100%;
          transform: skew(-25deg, 0deg);
          border: 1px solid #ddd;
          border-top: none;
        }
      }

      div.wizardStep.active {
        color: white;

        &:before {
          background: $custom-btn;
          z-index: -1;
        }

        &:after {
          background: $custom-btn;
          z-index: -1;
        }
      }
    }
  }

  .fileUploadInput {
    height: calc(1.5em + 0.75rem + 2px) !important;
    line-height: 1.7;

    &:after {
      padding: 0.2rem 0.6rem;
      height: auto;
      line-height: 2;
    }
  }

  td.dateSelection {
    input.pickDate {
      width: 100%;
      height: calc(1em + 0.5rem + 2px) !important;
      padding: 0 10px;
      border: 1px solid #e5e5e5;
      border-radius: 3px;
    }
  }

  .multi-select {
    font-size: 12.5px;

    .dropdown-heading {
      height: calc(1.3em + 0.75rem + 2px) !important;

      .dropdown-heading-dropdown-arrow {
        padding-top: 2px;

        svg {
          width: 20px;
          height: 17px;
        }
      }
    }

    .dropdown-content {
      padding-top: 6px;

      .panel-content {
        max-height: 265px;

        .select-panel {
          input {
            height: calc(1.3em + 0.75rem + 2px) !important;
          }

          .select-item {
            padding: 0 10px;
            margin-bottom: 2px;

            label input[type="checkbox"] {
              height: calc(0.6em + 0.75rem + 2px) !important;
            }
          }
        }
      }
    }
  }

  .selectCont {
    .css-yk16xz-control,
    .css-1fhf3k1-control,
    .css-1pahdxg-control {
      // max-height: calc(1.6em + 0.75rem + 2px) !important;
      min-height: 30px;

      &:focus,
      &:hover,
      &:active {
        border-color: #80bdff;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      }

      .css-1okebmr-indicatorSeparator {
        display: none;
      }

      .css-tlfecz-indicatorContainer {
        padding: 6px;

        &:hover,
        &:active {
          padding: 6px;
        }

        svg {
          width: 16px !important;
          height: 16px !important;
        }
      }
    }
  }

  /*******ShopDrawing menu styles*******/

  div.draftCont {
    min-height: 500px;
    // box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.15);

    div.tabList {
      border-bottom: 1px solid #eeeeee;
      display: inherit;
      font-size: 13px;
      font-weight: 600;

      a {
        padding: 3px 13px;
        cursor: pointer;

        &:first-child {
          padding-left: 5px;
        }
      }

      a.nav-link.active {
        border: none;
        box-shadow: none; 
        border-bottom: 3px solid $custom-btn;
      }
    }

    div.tabContent {
      padding-top: 10px;

      div.uploadPage {
        text-align: center;
        padding: 0 25px;
      }

      div.uploadFile {
        min-width: 500px;
        min-height: 300px;
        border: 2px dotted #ddd;
        padding-top: 15px;

        div.uploadIcon,
        div.uploadText {
          text-align: center;
          font-size: medium;
          padding: 20px 40px 40px;
        }

        div.uploadIcon i {
          font-size: xx-large;
        }
      }

      div.custom-file {
        label {
          overflow: hidden;
        }
      }
    }
  }

  .sdIcons {
    padding-left: 15px;
    margin-left: -13px;
    padding-top: 3px;
  }

  .mtcFile {
    .header {
      font-weight: bold;
      font-size: 14px;
      padding-bottom: 5px;
    }

    .text {
      padding-top: 24px;
      font-size: 14px;
    }
  }

  .mtcExport {
    text-align: center;
    vertical-align: middle;
    padding-bottom: 126px;
    padding-top: 60px;
    opacity: 0.9;
    border: 2px dotted #ddd;
  }

  div.newRevisionTabs {
    min-height: 280px;
    // box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.15);

    div.tabList {
      border-bottom: 1px solid #eeeeee;
      display: inherit;
      font-size: 13px;
      font-weight: 600;

      a {
        padding: 3px 24px;
        cursor: pointer;
        font-weight: 400;

        &:first-child {
          padding-left: 5px;
        }
      }

      a.nav-link.active {
        border: none;
        box-shadow: none;
        border-bottom: 3px solid $custom-btn;
      }
    }

    div.tabContent,
    div.compareSD,
    .hybridMBL,
    .mtcFile {
      padding-top: 10px;

      div.uploadNewRevision {
        div.uploadPage {
          text-align: center;
        }

        div.uploadFile {
          min-height: 270px;
          border: 2px dotted #ddd;
          padding-top: 15px;

          div.uploadIcon {
            text-align: center;
            padding: 48px 40px 30px;
          }

          div.uploadText {
            text-align: center;
            font-size: 14px;
            padding: 0 40px 70px;
          }
        }
      }

      div.fileComp,
      div.fileValidation {
        min-height: 270px;
        padding: 10px;
        border: 1px solid #ddd;
      }
    }
  }

  .successCheck {
    font-size: 20px;
    color: #2ecc71;
  }

  .failedIcon {
    font-size: 20px;
    color: #e74c3c;
  }

  .revHistoryAcc {
    padding: 0 5px 4px;

    .revName {
      cursor: pointer;
      width: fit-content;
      padding-bottom: 7px;
      font-weight: 600;

      i {
        margin-right: 2px;
        font-size: 13px;
      }
    }

    .revBody {
      padding: 2px 35px;

      .revFiles {
        padding: 3px 0;
        color: $custom-btn;
        cursor: pointer;
        width: max-content;

        i {
          margin-right: 4px;
          color: gray;
          opacity: 0.8;
        }
      }
    }
  }

  /****PLInputs menu styles****/

  .addPackageCont {
    max-height: 455px;
    overflow: hidden;
    padding-bottom: 0;
    padding-top: 5px;

    .addedListTable_piping {
      max-height: 358px;

      tr {
        line-height: 2;
      }

      th,
      td {
        padding: 0.2rem 0.5rem;
      }
    }

    .addedListTable {
      max-height: 358px;

      // overflow: auto;
      th,
      td {
        padding: 0.2rem 0.5rem;
      }

      input.markQuantity {
        height: calc(1em + 0.8rem + 2px) !important;
      }
    }

    div.react-bootstrap-table-pagination-total {
      margin-bottom: 8px;
    }

    ul.pagination {
      margin-bottom: 5px;
    }
  }

  .addPackageFooter {
    max-height: 75px;
  }

  .alignModal {
    margin-top: 110px;
  }

  .proceedModal {
    background-color: lightgray;
  }

  .plTextArea {
    resize: none;
  }

  table {
    div.packageName span {
      color: $menu-active-color;
      cursor: pointer;
    }

    div.addList i {
      cursor: pointer;
      font-size: large;
      font-weight: bold;
    }
  }

  .alignFormText {
    padding-top: 3px;
    padding-right: 0;
    font-size: 14px;
  }

  /**********Project Menu Styles***************/
  .projectCont,
  .empCont {
    div.projectDiv {
      border: 1px solid rgb(196, 194, 194);
      border-radius: 5px;
      min-height: 105px;
      margin-right: 10px;
      margin-bottom: 15px;
      max-width: 23%;

      div.projectTitle {
        padding: 10px;
        border-bottom: 1px solid #ddd;
        position: relative;

        span.projectName {
          font-weight: bold;
        }

        i.actionIcon {
          float: right;
          position: absolute;
          top: 13px;
          right: 0;
          padding-right: 10px;
          cursor: pointer;
        }
      }
    }
  }

  .projDiv {
    border-radius: 3px;
    box-shadow: 0 2px 10px 0 rgba(69, 90, 100, 0.2);

    a {
      color: inherit;
    }

    .projName {
      padding: 6px 20px;

      .projectTitle {
        font-size: 14px;
      }
    }

    .projBody {
      padding: 8px 10px;

      .projDetails {
        padding-left: 27px;

        span {
          font-weight: bold;
        }
      }
    }

    .groupStr {
      min-height: 387px;
    }

    .grpStrLess {
      min-height: 387px;
    }

    .groupPipe {
      min-height: 290px;
    }

    i.actionIcon {
      float: right;
      margin-top: 3px;
      cursor: pointer;
    }
  }

  .viewFile {
    max-height: 450px;
    overflow: scroll;
  }

  .projectForm,
  .companyForm,
  .vendorForm {
    div.projectLogo {
      border: 1px solid #ccc;
      width: 70px;
      height: 70px;
      cursor: pointer;
      border-radius: 2px;

      section,
      div.uploadLogo {
        height: 100%;

        img#companyLogo {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  /**********DMS styles**************/
  .dmsClear {
    position: absolute;
    top: 18px;
    right: 21px;
    font-size: 15px;
    cursor: pointer;
  }

  .dmsMain {
    .folderStructureCont {
      padding-right: 0;
      padding-left: 0;
      border-right: 1px solid #eee;
      min-height: 600px;

      .folderHeader {
        padding: 6px 12px;
        font-weight: 600;
        font-size: 13px;
        border-bottom: 1px solid #ddd;
        margin: 0 0 10px -11px;

        .card-title {
          margin-bottom: 0;
        }
      }
    }
  }

  /************ User styles*******************/
  .userForm,
  .projSettingsForm {
    .userSelection,
    .hybridCols {
      .menuListBox .react-dual-listbox {
        height: 200px;
      }

      .plBox {
        .react-dual-listbox {
          height: 275px !important;
        }
      }

      input.rdl-filter {
        height: calc(1.3em + 0.75rem + 2px) !important;
        border-radius: 3px;
        margin-bottom: 5px;
      }

      .rdl-control-container {
        select.rdl-control {
          border-radius: 5px;
          font-size: 13px;
        }
      }

      button.rdl-move {
        padding: 3px 5px;

        span {
          font-size: 12px;
        }
      }
    }
  }

  /***********All Packages styles**************/
  .allPackagesMain {
    .groupBy i {
      margin: 0;
    }

    .gropuPackagesTable {
      th.groupHeader {
        background-color: #d9d9d9;
      }

      td.groupRow {
        cursor: pointer;
      }
    }

    .accordianGroup {
      margin-bottom: 10px;
      border: 1px solid #ddd !important;
      border-radius: 3px;
      // box-shadow: none;

      .groupHeader {
        padding: 10px 25px;
        border-bottom: 1px solid #ddd;

        span.accIcon {
          float: right;

          i {
            font-size: large;
            font-weight: bolder;
          }
        }
      }
    }
  }

  /**********Column Preference styles*************/
  .columnPreference {
    input.rdl-filter {
      border-radius: 3px;
      height: 33px;
    }

    .rdl-control-container {
      border-radius: 3px;
      height: 270px;

      select.rdl-control {
        option {
          margin-bottom: 2px;
        }
      }
    }
  }

  /**********Dashboard styles***************/
  .dashboardMain {
    .dbTabs {
      position: relative;

      .currChange {
        position: absolute;
        right: 130px;
        color: black !important;
        font-weight: bold;
      }

      .dbSync {
        position: absolute;
        right: 0;
        color: black !important;
        font-weight: bold;

        .progressStatus {
          color: red;
          font-size: 19px;
          margin-right: 3px;
        }

        .compStatus {
          color: #2ecc71;
          font-size: 19px;
        }
      }
    }

    .noChartData {
      padding: 50px;
      text-align: center;
    }

    .card {
      border: 1px solid #ddd;
      border-radius: 3px;
      margin-bottom: 10px;

      .filterListCardHeader {
        padding: 3px 20px 0;
      }

      .filterListCardBody {
        padding: 0 20px;
      }

      .dbFilter {
        height: 350px;
      }

      .customToggleBtn {
        margin-top: 5px;
        margin-bottom: 0;
        padding: 0 0.4rem;
        font-size: 12px;
      }

      .widgetHeader {
        padding: 7px 20px;
      }
    }

    .parentCardBody {
      padding-bottom: 35px !important;

      .selectCurrency {
        height: calc(1em + 1rem) !important;
      }
    }

    .offset-md-3 {
      margin-left: 26%;
      padding-top: 0;
      // margin-top: -6px;
      margin-top: 40px;
    }

    .clearFilter {
      top: 9px;
      opacity: 0.6;
      font-size: 12px;
      right: -13px;
    }
  }

  /**********Purchase menu styles***************/
  .poAcc {
    padding: 7px 5px;
    border: 1px solid #eee;
    border-radius: 3px;

    .poAccHeader {
      cursor: pointer;
      font-weight: 600;

      i {
        margin-right: 3px;
      }
    }
  }

  /*************Compare Shop Drawing***********************/
  .getInput {
    border: 1px solid #e5e5e5;
    border-radius: 3px;
    // position: static;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    padding-top: 5px;
    // padding-bottom: 10px;
  }

  .swapIcon {
    font-size: 35px;
    position: absolute;
    top: 19vh;
    left: -3vh;
    border: 1px solid $custom-btn;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
    color: white;
    background-color: $custom-btn;
    z-index: 1;
  }

  .compHeader {
    padding-bottom: 5px;
    font-weight: 600;
    font-size: 13.5px;
  }

  .shopDrawingCompare {
    .compareSD {
      padding-top: 5px !important;
    }

    .padCls1 {
      padding-right: 30px;
    }

    .padCls2 {
      padding-left: 30px;
    }

    .marCls1 {
      margin-bottom: 10px;
      margin-right: -30px;
      border-bottom: 1px solid #e5e5e5;
    }

    .marCls2 {
      margin-bottom: 10px;
      margin-left: -30px;
      border-bottom: 1px solid #e5e5e5;
    }
  }

  /*******************Notifications*****************/
  .notifications {
    .scrollLoader {
      color: gray;
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
    }

    .infinite-scroll-component {
      overflow-x: hidden !important;
    }

    .emptyData {
      top: 40%;
      position: absolute;
      left: 38%;
      font-size: 14px;
    }

    .notifyList {
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
      padding: 0 5px;
      height: 550px;
      overflow-y: auto;
      overflow-x: hidden;

      hr {
        margin: 0;
        border-top: 1px solid #ccc;
      }

      .notifyBg {
        background-color: #f5f5f5;
        font-weight: bold;
      }

      .notifyImp {
        color: #e74c3c;
        opacity: 1;
      }
    }

    .notifyIcon {
      font-size: 18px;
      opacity: 0.5;
    }

    .timeIcon {
      font-size: 19px;
      padding-right: 2px;
    }

    .tag {
      border-radius: 10px;
      padding: 0 5px;
      color: white;
    }

    .createAction {
      border: 1px solid #2ecc71;
      background: #2ecc71;
    }

    .editAction {
      border: 1px solid #f9a825;
      background: #f9a825;
    }

    .deleteAction {
      border: 1px solid #e74c3c;
      background: #e74c3c;
    }

    .releaseAction {
      border: 1px solid #35d6ed;
      background: #35d6ed;
    }

    .approvalAction {
      border: 1px solid $custom-btn;
      background: $custom-btn;
    }

    .disableRec {
      color: gray;
    }
  }

  .headerActions {
    .selectDiscipline {
      font-size: 13px;
      border: 1px solid #ddd;
      border-radius: 4px;
      width: 150px;
      margin-right: 5px;
      padding: 1px 3px;
    }
    .helpIcon {
      padding-right: 7px;
      font-size: 15px;
    }
  }

  .statusTable {
    max-height: 291px;
    overflow-y: auto;
  }

  /**********Report menu styles***************/

  .dragCont {
    .headerText {
      padding: 5px 0 10px;
      font-size: 13px;
    }
    .dragBody {
      border: 1px solid #ccc;
      border-radius: 5px;
      margin: 0px;
      padding: 15px;
    }

    .item {
      border-top: 1px solid #ffffff;
      background-color: #eeeeee;
      border-bottom: 1px solid #ccc;
      border-radius: 6px;
      margin-bottom: 4px;
      padding: 6px;
      padding-left: 8px;
      opacity: 1;
    }

    .item .title {
      margin: 0;
      margin-bottom: 0.3rem;
    }

    .item .body {
      margin: 0;
      margin-bottom: 0.3rem;
    }

    .dragged .item {
      opacity: 0.25;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ffffff;
    }

    .floating .item {
      background-color: #ffffff;
      box-shadow: 0 4px 20px #666666;
    }

    .dl-item.floating {
      width: 85%;
    }
  }

  .bulkUpload {
    padding: 0 20px;
    width: 100%;
    .files {
      text-align: left;
      padding: 0 20px 20px;
      .filesList {
        // max-height: 150px;
        // overflow-y: scroll;
        padding: 5px 10px;
        div {
          padding: 5px 0;
          .fileIcon {
            font-size: 1.4rem;
            padding-right: 3px;
          }
          .excel {
            color: green;
          }
          .pdf {
            color: #e94a4a;
          }
        }
      }
      .viewFiles {
        font-weight: bold;
        text-decoration: underline;
        font-size: 13px;
        padding-top: 5px;
      }
    }
  }

  .viewMTC {
    color: blue;
    cursor: pointer;
  }

  .viewMTCFiles {
    max-height: 350px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 10px;
    .viewFiles {
      font-weight: bold;
      text-decoration: underline;
      font-size: 13px;
    }
    .MTCList {
      padding: 5px;
      .fileIconPDF {
        font-size: 1.4rem;
        padding-right: 3px;
        color: #e94a4a;
        margin-top: -15px;
      }
      span.fileName {
        display: inline-block;
        padding-right: 20px;
        width: 350px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: default;
      }
      svg {
        margin-top: -15px;
      }
    }
  }

  x .tooltip,
  .popover {
    z-index: 9999 !important;
  }

  // .filesToolTip {
  .tooltip-inner {
    border-radius: 5px !important;
    padding: 10px 10px;
    box-shadow: 0 0 15px gray;
    max-width: 300px;
    color: #555d50;
    background-color: #fff;
    font-size: 12.5px;
    max-height: 150px;
    overflow-y: scroll;
  }
  .arrow {
    &:before {
      color: #fff !important;
    }
  }

  // }
}
